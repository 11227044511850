* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Encode Sans Expanded", sans-serif;
}

::-webkit-scrollbar {
  width: 15px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #ff6584;
  border-radius: 8px;
}
